<div class="left-nav">
    <h3>Study File Upload</h3>
    <ul>
        <li (click)="changeFontColor('file-upload', 'Upload Study Files')" [ngStyle]="{'color': pageHeader === 'Upload Study Files' ? 'black' : 'gray', 'font-weight': pageHeader === 'Upload Study Files' ? 'bold' : ''}">Upload Study Files</li>
        <li (click)="changeFontColor('subscription', 'Notification Subscription')" [ngStyle]="{'color': pageHeader === 'Notification Subscription' ? 'black' : 'gray', 'font-weight': pageHeader === 'Notification Subscription' ? 'bold' : ''}">Notification Subscription</li>
        <!-- <li (click)="changeFontColor('coming-soon', 'Coming Soon')" [ngStyle]="{'color': pageHeader === 'Coming Soon' ? 'black' : 'gray', 'font-weight': pageHeader === 'Coming Soon' ? 'bold' : ''}">Coming Soon - Test</li> -->
    </ul>



</div>