import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { PageHeaderService } from 'src/app/services/pageheader';
import { ProfileType } from 'src/app/models/profile-type';
import { StateService } from 'src/app/services/state.service';

// Endpoint for retrieving MS Graph profile for login information
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';


@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  pageHeader: string = 'PROFILE'
  profile!: ProfileType;

  constructor(
    readonly profileService: ProfileService,
    readonly pageHeaderService: PageHeaderService,
    private stateService: StateService
  ) {
    this.pageHeaderService.emitData(this.pageHeader);
  }

  ngOnInit(): void {
    this.getProfile();
  }

  getProfile(): void {
    console.log('localTest ngOnInit about to call profileService.getProfile');
    this.profileService.getProfile().subscribe((res: ProfileType) => {
      console.log('return res = ' + JSON.stringify(res));
      this.profile = res;
    });
  }
}
