import { Component, OnInit } from '@angular/core';
import { Inject, Injectable, InjectionToken } from '@angular/core';

// config
import { environments } from '../../../config/env.config';
import { env } from '../../../config/env';

export interface EnvironmentConfig {
  local?: any;
  sbx?: any;
  dev?: any;
  tst?: any;
  qa?: any;
  uat?: any;
  pilot?: any;
  prod?: any;
}

interface CurrentEnvironment {
  env: string;
}

@Component({
  selector: 'ui-config',
  templateUrl: './ui-config.component.html',
  styleUrls: ['./ui-config.component.scss']
})

@Injectable({
  providedIn: 'root',
})
export class UiConfigComponent  {

  // tslint:disable-next-line:variable-name
  env: string;
  environments: object;
  config: object;

  constructor(
  ) {
    this.env = env.env;
    // console.log('ConfigComponent constructor, config = ' + this._env);
    this.environments = environments[env.env];
    // console.log('ConfigComponent constructor, enviromnets = ' + JSON.stringify(this._environments));
    this.config = this.environments;
  }



  public getConfig(key: any) {
    // console.log('getConfig called for key, config = ' + JSON.stringify(this.config));
    if (this.config[key] === undefined) {
      console.error(`AppConfig: value ${key} not found in app config.`);
    }
    return this.config[key];
  }
}
