import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class StudyFileValidationService {
    private selectedFile: File | null = null;

    setSelectedFile(file: File): void {
        this.selectedFile = file;
    }

    getSelectedFile(): File | null {
        return this.selectedFile;
    }

    private readFileAsText(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
            reader.readAsText(file);
        });
    }

    async loadAndValidateJsonFile(expectedJurisdiction: string): Promise<[boolean, string]> {
        if (!this.selectedFile) {
            console.error('No file selected');
            return [false, 'No File selected'];
        }

        try {
            const fileContent = await this.readFileAsText(this.selectedFile);
            const jsonData = JSON.parse(fileContent);

            // Define the required schema elements based on DEF_WCluster_Base
            const requiredL1Keys = ['wcResults'];
            const requiredL2Keys = ['id', 'title', 'buses', 'StressGens', 'flowgates'];

            // Check if all required L1 schema elements are present
            const isValidL1 = requiredL1Keys.every((element) => element in jsonData);

            if (!isValidL1) {
                const msg = `Missing required keys at top level: ${requiredL1Keys}`;
                console.error(msg);
                return [false, msg];
            }

            for (let idx = 0; idx < jsonData['wcResults'].length; idx++) {
                const item = jsonData['wcResults'][idx];

              // Check if all required L2 schema elements are present
                const isValidL2 = requiredL2Keys.every((element) => element in item);

                if (!isValidL2) {
                    const msg = `Missing one or more of required keys in item ${idx}: ${requiredL2Keys}`;
                    console.error(msg);
                    return [false, msg];
                }

                const jurisdiction = item['buses'][0]['busarea'].trim();
                if (jurisdiction.toLowerCase() !== expectedJurisdiction.toLowerCase()) {
                    const msg = `Expected Jurisdiction not equal to detected: ${expectedJurisdiction} vs ${jurisdiction}`
                    console.error(msg);
                    return [false, msg ];
                }

                console.log(`Detected Jurisdiction: ${jurisdiction}`);
            }

            return [true, "File valid"];
        } catch (error) {
            console.error('Error reading or parsing the file', error);
            return [false,"Validation Failure"];
        }
    }

}