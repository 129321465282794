import { Injectable } from '@angular/core';

import { HttpClient, HttpEventType } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { MessageService } from './message.service';
import { UiConfigComponent } from '../components/ui-config/ui-config.component';


@Injectable({
    providedIn: 'root',
})

export class GetRecordStatusService {

    constructor(
        private messageService: MessageService,
        private httpClient: HttpClient,
        private config: UiConfigComponent,
    ) { }



    public getRecordsTable(prefix: string): Observable<object> {

        const apiUrl = this.config.environments['aws_service_api'] + '/record-tracking?jurisdiction=' + prefix;
        return this.httpClient.get<object>(apiUrl);
    }


    private log(message: string): void {
        console.log(message);
        this.messageService.add(`GetRecordStatusService: ${message}`);
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T): any {
        return (error: any): Observable<T> => {
        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead

        // TODO: better job of transforming error for user consumption
        this.log(`${operation} failed: ${error.message}`);

        // Let the app keep running by returning an empty result.
        return of(result as T);
        };
    }
}
