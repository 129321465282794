import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ProfileComponent } from './components/profile/profile.component';
import { FailedComponent } from './components/failed/failed.component';

import { UnauthorizeComponent } from './components/unauthorize/unauthorize.component';
import { FormComponent } from './resuable_components/form/form.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'coming-soon',
    component: ComingSoonComponent,
    // canActivate: [MsalGuard],
    data:{expctedRole: ['disis_heatmap_admin_ui']} ,
  },
  {
    path: 'unauthorize',
    component: UnauthorizeComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'login-failed',
    component: FailedComponent,
  },
  {
    path: 'file-upload',
    component: FileUploadComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'subscription',
    component: SubscriptionComponent,
    canActivate: [MsalGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      // Remove this line to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
