import { Component, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';

import { UiConfigComponent } from '../ui-config/ui-config.component';
import { StateService } from 'src/app/services/state.service';
import { ProfileService } from 'src/app/services/profile.service';
import { ProfileType } from 'src/app/models/profile-type';

@Component({
  selector: 'subscription',
  // standalone: true,
  // imports: [],
  templateUrl: './subscription.component.html',
  styleUrl: './subscription.component.scss'
})
export class SubscriptionComponent {

  profile: ProfileType | null = null; // Add a profile property

  constructor(
    readonly httpClient: HttpClient,
    readonly stateService: StateService,
    readonly profileService: ProfileService,
    readonly changeDetectorRef: ChangeDetectorRef, // Inject ChangeDetectorRef
    private config: UiConfigComponent // Inject the ConfigService
  ) {}

  ngOnInit(): void {
    console.log('init file-upload');
    // Subscribe to the profile state
    this.stateService.profile$.subscribe((profile) => {
      this.profile = profile;
      console.log('Profile state:', this.profile);
      this.changeDetectorRef.detectChanges(); // Manually trigger change detection
    });

    if (this.profile === null) {
      console.log('Profile is null, fetching profile');
      this.profileService.getProfile().subscribe();
    }
   }

  ngOnDestroy(): void { }

  callAwsServiceApi(): void {
    const apiUrl = this.config.environments['aws_service_api'] + '/subscription';

    // const formData = new FormData();
    // formData.append('email_addr', this.profile.userPrincipalName);

    const payload = {
      user_email: this.profile.userPrincipalName,
    };

    this.httpClient.post(apiUrl, payload, {
        observe: 'events', //Oberve events to track progress
      })
      .subscribe({
        next: (response) => {
          console.log('AWS Service API response:', response);
        },
        error: (error: HttpErrorResponse) => {
          console.error('Error calling AWS Service API:', error);
        }
      });
  }

}
