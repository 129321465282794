

<input type="file" (change)="handleFileSelect($event)">
<p *ngIf="this.fileService.getSelectedFile()">Selected file: {{ this.fileService.getSelectedFile().name }}</p>

<div class="validation-box">
    <input type="checkbox" id="validateFile" [(ngModel)]="validateFile" />
    <label for="validateFile">Validate File</label>
</div>



<div class="upload-container">
    <div class="button-group">
        <button  [disabled]="!this.fileService.getSelectedFile()" (click)="onClick('dec')">Upload DEC</button>
    </div>
    <div class="upload-info">
        <p>Uploaded By: {{ uploadRecordDec.uploader }}</p>
        <p>Uploaded Date: {{ uploadRecordDec.upload_dt  }}</p>
    </div>
</div>

<div class="upload-container">
    <div class="button-group">
        <button  [disabled]="!this.fileService.getSelectedFile()" (click)="onClick('dep')">Upload DEP</button>
    </div>
    <div class="upload-info">
        <p>Uploaded By: {{ uploadRecordDep.uploader }}</p>
        <p>Uploaded Date: {{ uploadRecordDep.upload_dt  }}</p>
    </div>
</div>

<div class="upload-container">
    <div class="button-group">
        <button  [disabled]="!this.fileService.getSelectedFile()" (click)="onClick('def')">Upload DEF</button>
    </div>
    <div class="upload-info">
        <p>Uploaded By: {{ uploadRecordDef.uploader }}</p>
        <p>Uploaded Date: {{ uploadRecordDef.upload_dt  }}</p>
    </div>
</div>

<div *ngIf="notificationMessage" class="notification-banner" [ngStyle]="{'background-color': notificationColor}">
    {{ notificationMessage }}
</div>


<div *ngIf="uploadProgress !== null">
    <p> Upload Progress: {{uploadProgress}}%</p>
</div>

<div *ngIf="uploadStatus">
    <p>Status: {{uploadStatus}}</p>
</div>
<p>Pre-signed URL: {{ presignedUrl['url'] }}</p>
<p>Target path: {{ presignedUrl['fields']['key'] }}</p>

<!-- <p>Fields->key: {{ presignedUrl['fields']['key'] }}</p>
<p>Fields->policy: {{ presignedUrl['fields']['policy'] }}</p>
<p>Fields->signature: {{ presignedUrl['fields']['signature'] }}</p>
<p>Fields->sec token: {{ presignedUrl['fields']['x-amz-security-token'] }}</p>
<p>Fields -> "x-amz-algorithm": {{presignedUrl['fields']['x-amz-algorithm']}} </p>
<p>Fields -> "x-amz-credential": {{presignedUrl['fields']['x-amz-credential']}}</p>
<p>Fields -> "x-amz-date": {{presignedUrl['fields']['x-amz-date'] }} </p>
<p>Fields -> "x-amz-signature": {{presignedUrl['fields']['x-amz-signature']}}</p> -->

<div *ngIf="profile">
    <p>Profile Email: {{ profile.userPrincipalName }}</p>
    <p>Profile Name: {{ profile.givenName }} {{ profile.surname }}</p>
  </div>