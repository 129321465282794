import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ProfileType } from '../models/profile-type';
import { UploadRecord } from '../models/upload-record';

@Injectable({
    providedIn: 'root',
})
export class StateService {
    private profileSubject = new BehaviorSubject<ProfileType | null>(null);
    profile$ = this.profileSubject.asObservable();

    // private uploadRecordSubject = new BehaviorSubject<UploadRecord | null>(null);
    // uploadRecord$ = this.uploadRecordSubject.asObservable();

    setProfile(profile: ProfileType): void {
        this.profileSubject.next(profile);
    }

    // setUploadRecord(jurisdiction: string, record: object): void {
    //     this.profileSubject.next(record);
    // }
}