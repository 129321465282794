import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {firstValueFrom, interval, Subscription } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';

import { GetUrlService } from 'src/app/services/geturl.service';
import { StateService } from 'src/app/services/state.service';
import { ProfileType } from 'src/app/models/profile-type';
import { ProfileService } from 'src/app/services/profile.service';
import { UiConfigComponent } from '../ui-config/ui-config.component';
import { switchMap, startWith } from 'rxjs/operators';
import { GetRecordStatusService } from 'src/app/services/getrecordstatus.service';
import { UploadRecord } from 'src/app/models/upload-record';
import { StudyFileValidationService } from 'src/app/services/studyfilevalidation.service';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {

  presignedUrl: object = {
    "url": null,
    "fields": {
      "key": "",
      "policy": "sec token",
      "signature": "sig",
      "x-amz-security-token": "x-amz-security-token-placeholder",
      "x-amz-algorithm": "placeholder",
      "x-amz-credential": "placeholder",
      "x-amz-date": "placeholder",
      "x-amz-signature": "placeholder"
    }
  };


  selectedFile: File | null = null;
  uploadProgress: number | null = null;
  uploadStatus: string = '';
  profile: ProfileType | null = null; // Add a profile property
  readonly subscription: Subscription = new Subscription();
  notificationMessage : string | null = null;
  notificationColor : string | null = null;

  uploadRecordDec : UploadRecord = new UploadRecord();
  uploadRecordDep : UploadRecord = new UploadRecord();
  uploadRecordDef : UploadRecord = new UploadRecord();
  validateFile: boolean = true;

  constructor(
    readonly getUrlService: GetUrlService,
    readonly httpClient: HttpClient,
    readonly stateService: StateService,
    readonly profileService: ProfileService,
    readonly changeDetectorRef: ChangeDetectorRef, // Inject ChangeDetectorRef
    readonly config: UiConfigComponent,
    readonly recordStatusService: GetRecordStatusService,
    readonly fileService: StudyFileValidationService
  ) {}

  ngOnInit(): void {
    console.log('init file-upload');
    // Subscribe to the profile state
    this.stateService.profile$.subscribe((profile) => {
      this.profile = profile;
      console.log('Profile state:', this.profile);
      this.changeDetectorRef.detectChanges(); // Manually trigger change detection
    });

    if (this.profile === null) {
      console.log('Profile is null, fetching profile');
      this.profileService.getProfile().subscribe();
    }

    // Poll the API every 30 seconds
    const INTERVAL = 30000; //ms
    this.subscription.add(
      interval(INTERVAL)
        .pipe(
          startWith(0),
          switchMap(() => this.recordStatusService.getRecordsTable('dec'))
        )
        .subscribe({
          next: (data) => {
            // console.log('Fetched data:', data);
            // Handle the fetched data
            if (data === null) {
              this.uploadRecordDec.uploader = "No Record Found"
              this.uploadRecordDec.upload_dt = "No Record Found"
            } else {
              this.uploadRecordDec.uploader = data['email_address'];
              this.uploadRecordDec.upload_dt = data['dt_transfer_complete'] + ' UTC';
            }
          },
          error: (error: HttpErrorResponse) => {
            console.error('Error fetching data:', error);
          }
        })
    );

    this.subscription.add(
      interval(INTERVAL)
        .pipe(
          startWith(0),
          switchMap(() => this.recordStatusService.getRecordsTable('dep'))
        )
        .subscribe({
          next: (data) => {
            // console.log('Fetched data:', data);
            // Handle the fetched data
            if (data === null) {
              this.uploadRecordDep.uploader = "No Record Found"
              this.uploadRecordDep.upload_dt = "No Record Found"
            } else {
              this.uploadRecordDep.uploader = data['email_address'];
              this.uploadRecordDep.upload_dt = data['dt_transfer_complete'] + ' UTC';
            }
          },
          error: (error: HttpErrorResponse) => {
            console.error('Error fetching data:', error);
          }
        })
    );

    this.subscription.add(
      interval(INTERVAL)
        .pipe(
          startWith(0),
          switchMap(() => this.recordStatusService.getRecordsTable('def'))
        )
        .subscribe({
          next: (data) => {
            // console.log('Fetched data:', data);
            // Handle the fetched data
            if (data === null) {
              this.uploadRecordDef.uploader = "No Record Found"
              this.uploadRecordDef.upload_dt = "No Record Found"
            } else {
              this.uploadRecordDef.uploader = data['email_address'];
              this.uploadRecordDef.upload_dt = data['dt_transfer_complete'] + ' UTC';
            }
          },
          error: (error: HttpErrorResponse) => {
            console.error('Error fetching data:', error);
          }
        })
    );
  } //ngOnInit

  ngOnDestroy(): void {
    // Unsubscribe from the interval when the component is destroyed
    this.subscription.unsubscribe();
  }

  handleFileSelect(event: Event) {
    // this.selectedFile = event.target.files[0];
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {

      this.fileService.setSelectedFile(input.files[0]);
    }
  }

  async onClick(prefix: string) {
    try {
      if (!this.fileService.getSelectedFile()){
        console.warn('Upload clicked with no file selected!!');
        return;
      }

      if (this.validateFile) {
        const [validated, msg] = await this.fileService.loadAndValidateJsonFile(prefix);
        if (!validated) {
          console.log('File failed validation.');
          this.showNotification('File failed validation.' + msg, 'red');
          return;
        }
    }

      // Add confirmation box
      const confirmed = window.confirm(`Are you sure you want to upload ${this.fileService.getSelectedFile().name} for ${prefix.toUpperCase()} ?`);
      if (!confirmed) {
        console.log('File upload cancelled by user.');
        return;
      }

      console.log('Button clicked!');

      const apiResult = await firstValueFrom(this.getUrlService.getPresignedUrl(prefix));
      console.log('API Result:', apiResult);
      this.presignedUrl['url'] = apiResult['url'];
      this.presignedUrl['fields']['key'] = apiResult['fields']['key'];
      this.presignedUrl['fields']['x-amz-security-token'] = apiResult['fields']['x-amz-security-token'];
      this.presignedUrl['fields']['x-amz-algorithm'] = apiResult['fields']['x-amz-algorithm'];
      this.presignedUrl['fields']['x-amz-credential'] = apiResult['fields']['x-amz-credential'];
      this.presignedUrl['fields']['x-amz-date'] = apiResult['fields']['x-amz-date'];
      this.presignedUrl['fields']['x-amz-signature'] = apiResult['fields']['x-amz-signature'];
      this.presignedUrl['fields']['policy'] = apiResult['fields']['policy'];

      if (!this.fileService.getSelectedFile() || !this.presignedUrl['url']) {
        console.error('Selected file or presigned URL is missing');
        return;
      } else {
        console.log("Uploading file: ", this.fileService.getSelectedFile().name);


        const formData = new FormData();
        formData.append('key', this.presignedUrl['fields']['key']);
        formData.append('x-amz-security-token', this.presignedUrl['fields']['x-amz-security-token']);
        formData.append('x-amz-algorithm', this.presignedUrl['fields']['x-amz-algorithm']);
        formData.append('x-amz-credential', this.presignedUrl['fields']['x-amz-credential']);
        formData.append('x-amz-date', this.presignedUrl['fields']['x-amz-date']);
        formData.append('x-amz-signature', this.presignedUrl['fields']['x-amz-signature']);
        formData.append('policy', this.presignedUrl['fields']['policy']);
        formData.append('file', this.fileService.getSelectedFile());

        this.httpClient.post(this.presignedUrl['url'], formData, {
          reportProgress: true,
          observe: 'events', //Oberve events to track progress
        }).subscribe({
            next: (event) => {
                if (event.type === HttpEventType.UploadProgress && event.total) {
                  this.uploadProgress = Math.round(100*event.loaded / event.total);
                } else if (event.type === HttpEventType.Response) {
                  this.uploadStatus = 'Upload Complete!';
                  this.showNotification('File upload complete.', 'green');
                  // call the the API to update the record tracking DB

                  const jurisdiction=prefix;
                  const user_email= this.profile.userPrincipalName;
                  const source_file_name=this.fileService.getSelectedFile().name;
                  const staging_file_name=this.presignedUrl['fields']['key'];
                  const payload = {
                    jurisdiction: jurisdiction,
                    user_email: user_email,
                    source_file_name: source_file_name,
                    staging_file_name: staging_file_name
                  };


                  console.log(`Calling record-tracking API with params: ${JSON.stringify(payload)}`);

                  this.httpClient.post(`${this.config.environments['aws_service_api']}/record-tracking`, payload)
                    .subscribe({
                      next: (response) => {
                        console.log('AWS Service API response:', response);
                      },
                      error: (error: HttpErrorResponse) => {
                        console.error('Error calling AWS Service API:', error);
                      }
                    });
                }
            },
            error: (err: HttpErrorResponse) => {
              this.uploadStatus = 'Upload failed!';
              console.error('Upload error', err);
              this.showNotification('File upload error.', 'red');
            }
        }); //subscribe

      }//else

      } catch(error) {
      console.error('Error During API Call:', error);
    } //try-catch

  }

  showNotification(message: string, color: string): void {
    this.notificationMessage = message;
    this.notificationColor = color;
    setTimeout(() => {
      this.notificationMessage = null;
      this.notificationColor = null;
    }, 5000);
  }

}
